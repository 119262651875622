/* A place to keep the map object and related items */

import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import * as constants from './constants.js';

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

//eRoadMAP token
mapboxgl.accessToken = constants.mapboxglToken;

const map = new mapboxgl.Map({
    container: 'map',
    style: `mapbox://styles/${constants.roadStyleId}`,
    center: [-120.282715, 40.462192],
    zoom: 3,
    maxBounds: constants.bounds,
    maxZoom: constants.maxMapZoom,
});

// Add zoom and rotation controls to the map
map.addControl(new mapboxgl.NavigationControl());

// Add Mapbox geocoder to the map to allow users to search by address
map.addControl(
    new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        placeholder: 'Search by address',
        zoom: 11,
        countries: 'US',
        // this will allow us to collapse the search window on mobile:
        collapsed: window.screen.width <= 768,
        clearOnBlur: true,
    }),
    'bottom-left'
);

// Add a scale bar to the lower right corner of the map, change default metric to Freedom Units because 'Merica
map.addControl(new mapboxgl.ScaleControl({ unit: 'imperial' }), 'bottom-right');

// Add some error handling because otherwise the console error panel goes wild.
// Unimplemented type: 4 means that MapBox encountered an empty tile, which we
// have plenty of in places where there is no data coverage

const IGNORABLE_ERROR_MESSAGE = 'Unimplemented type: 4';

map.on('error', (e) => {
    if (e.error.message == IGNORABLE_ERROR_MESSAGE) {
        return;
    }
    console.log('Received error from MapBox:', e.error.message);
});

export default map;
